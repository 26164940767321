import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { BsChevronLeft, BsChevronDown } from 'react-icons/bs';
import styles from './home.module.css';
import Header from "../components/header";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "bootstrap";
import Collapse from "react-bootstrap/Collapse"
import 'typeface-quicksand';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as getThreadsAction from '../redux/store/actions';
import * as getFaqsAction from '../redux/store/actions';
import Loading from "../components/loading";
import * as actions from '../redux/store/actions'

export default function Home(props) {

	const dispatch = useDispatch();

	const [arrShow, setArrShow] = useState([])

	const CMD_ADMIN_COMMENT = "admin_comment"
	const CMD_ADMIN_REQUEST_CLOSE = "admin_request_close"

	const [isFull, setIsFull] = useState(false);
	const isLoading = useSelector(state => state.threads.isLoading || state.allFaqs.isLoading)
	const [listFaqs, setListFaqs] = useState({})
	const whileLists = useSelector(state => state.notify.data)
	const [listNotifies, setListNotifies] = useState([])
	const event = useSelector(state => state.ws.event)
	const thread_id = useSelector(state => state.ws.tid)
	const eventCounter = useSelector(state => state.ws.count)
	const [allThreads, setAllThreads] = useState([])
	const threads = useSelector(state => state.threads.data)
	const allFaqs = useSelector(state => state.allFaqs.data)
	const isLoggin = useSelector(state => state.token.isLoggined)
	let auth = '';

	useEffect(() =>{
		console.log('start update 230206');
		console.log(auth)
		auth = localStorage.getItem('auth');
		console.log(auth)
	},[])

	useEffect(() => {
		if (whileLists != undefined) {
			setListNotifies(whileLists.map((item) => item.threadId))
		}
	}, [whileLists])

	useEffect(() => {
		console.log("event change")
		if (event == CMD_ADMIN_COMMENT || event == CMD_ADMIN_REQUEST_CLOSE) {
			console.log(thread_id)
			console.log(event)
			console.log(listNotifies)

			if (whileLists.filter(item => item.threadId == thread_id).length > 0) {
				console.log("co roi")
				return
			} else {
				console.log("chua co")
				dispatch(actions.addThreadIntoNotifyList(thread_id))
			}
		}
	}, [eventCounter])

	useEffect(() => {
		if (threads != undefined) {
			console.log(threads)
			setAllThreads(threads)
		}
	}, [threads])


	// let hasReloaded = localStorage.getItem('hasReloaded')
	// useEffect(() => {


	// 	if (localStorage.getItem("hasReloaded") === null) {
	// 		console.log("set item")
	// 		localStorage.setItem('hasReloaded', 1)
	// 	}


	// }, [])

	// useEffect(() => {
	// 	if (hasReloaded == 1) {
	// 		console.log("check thoi ma")
	// 		localStorage.setItem('hasReloaded', 0)
	// 		window.location.reload()


	// 	}
	// }, [hasReloaded])



	useEffect(() => {
		if (allFaqs !== undefined) {
			console.log(allFaqs)

			setListFaqs(groupArrayOfObjects(allFaqs, "categories"))
		}
	}, [allFaqs])

	useEffect(() => {
		if (isLoggin && auth != '') {
			fetchThreads()
			fetchFaqs()
			dispatch(actions.getNotifies(auth))
		}

	}, [])

	const fetchThreads = async () => {
		dispatch(getThreadsAction.getThreads(true, auth))
	}
	const fetchFaqs = async () => {
		dispatch(getFaqsAction.getAllFaq(auth))
	}


	const handleShow = (faq) => {
		// setShow(!show)
		// setIdShow(id)
		if (arrShow.includes(faq)) {
			setArrShow(arrShow.filter(item => item.id !== faq.id));
		} else {
			setArrShow([...arrShow, faq])
		}

	}

	const handleShowFull = () => {
		setIsFull(!isFull)
	}


	function groupArrayOfObjects(list, key) {
		return list.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	return (
		<div >
			<div className={styles.homeMenutop}>
				{/* <a href="/"><BsChevronLeft className={styles.icon} /></a> */}
				<a className={styles.content} href="#">VIP Support</a>
				<Link to={{ pathname: '/thread_create', state: { listImg: null, isDirect: false } }} ><button className={styles.btnCreate}>Create</button></Link>
			</div>
			{allThreads &&
				<div className={styles.yourThread} style={{ overflow: isFull ? "visible" : "", height: isFull ? "auto" : "50vh" }}>
					<div className={styles.titleYourThread}>your thread</div>
					<div className={styles.listThreads}>
						{allThreads != undefined && allThreads.map((thread, index) => (
							<div key={thread.id + index} className={styles.itemYourThread}>
								<div className={styles.categoryYourThread}>
									{thread.categories[0]}
									{listNotifies != undefined && listNotifies.some(id => id == thread.id) && <div className={styles.noti}></div>}
								</div>
								<div className={styles.detailYourThread}><Link to={{ pathname: '/thread_detail/' + thread.id }}>{thread.text}</Link></div>
								{/* <div className={styles.detailYourThread}>
									<div className={styles.text}><Link to={{ pathname: '/thread_detail/' + thread.id }}>{thread.text}</Link></div>
									{listNotifies != undefined && listNotifies.some(id => id == thread.id) && <div className={styles.noti}></div>}
								</div> */}

							</div>
						)).reverse()}
					</div>
					{/* <BsChevronDown className={styles.btnShow} onClick={() => {handleShowFull()}} /> */}
					{allThreads.length > 5 && < button className={styles.showmore} onClick={() => { handleShowFull() }} style={{ position: "absolute", right: "10px", bottom: "0px", border: "none", background: "none", color: "black" }}>{isFull ? "Show less" : "Show more..."}</button>
					}
				</div>}
			<div className={styles.faqList} style={{ position: "relative" }} >

				<div className={styles.titleFaq}>FAQ</div>
				<div className={styles.itemFaq} >
					{Object.keys(listFaqs).map((key, index) => {
						return (
							<div className={styles.faqDetail}>
								<div key={key + index} className={styles.categoryFaq}>{key}</div>
								{
									listFaqs[key].map((faq, ind) =>
									(
										<div key={faq.id + ind} className={styles.faqQues}>
											<div className={styles.question} onClick={(e) => handleShow(faq)} >
												{faq.title}<BsChevronDown className={styles.iconDown} />
											</div>
											{arrShow.includes(faq) && <div className={styles.ans} onClick={(e) => handleShow(faq)} >{faq.text}</div>}
										</div>
									)
									)
								}
							</div>
						)
					})}
				</div>
			</div>
			{isLoading && <Loading />}
		</div>
	)
}