import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import styles from './unauthen.module.css';

const UnAuthen = () =>{
  const [checklogin, setChecklogin] = useState();
  const isLoggin = useSelector(state => state.token.isLoggined);

  useEffect(() =>{
    console.log(isLoggin)
    if(isLoggin != undefined){
        setChecklogin(isLoggin);
    }
}, [isLoggin])

// if(checklogin){
//   return (
//     <Redirect to={"/"} />
// )
// }else{
//   return (
//     <div className={styles.unAuthen}>
//       <h3>ERROR 401 - Unauthorized</h3>
//       <h6 className={styles.text}>Sorry, your request  could not be processed.</h6>
//     </div>
//   );
// }
return (
  <>
  {checklogin == true && <Redirect to={"/"} />}
  <div className={styles.unAuthen}>
      <h3>ERROR 401 - Unauthorized</h3>
      <h6 className={styles.text}>Sorry, your request  could not be processed.</h6>
    </div>
  </>
)


} 

export default UnAuthen;
