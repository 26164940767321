import axios from "axios";
import * as api from '../../../api';
import { ACTION_SEND_TELEGRAM_MESSAGE } from ".";
// const chatId = "-1001856127466";
const chatId = process.env.REACT_APP_TELE_GROUPID

export const sendTelegramNotificationRequest = () => ({
    type: ACTION_SEND_TELEGRAM_MESSAGE,
    payload: {}
}) 

export const sendTelegramNotification = (message) => {
    return dispatch => {
        dispatch(sendTelegramNotificationRequest())
        const data = {
            chat_id: chatId,
            text: message,
            parse_mode: 'html'
        }
    
        api.sendMessageRequest(data)
    }
}