import socketIOClient from 'socket.io-client';
import { socketURL } from './base';
import React, { createContext } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from './redux/store/actions'

const WebSocketContext = createContext(null)
export { WebSocketContext }

export default ({ children }) => {
    const endpoint = socketURL;
    // const socket = socketIOClient(endpoint)

    const socket = socketIOClient({
        transports: ['websocket'],
        path: '/socket', // added this line of code
        withCredentials: true
    })

    const dispatch = useDispatch()

    let ws;
    const CMD_USER_CREATE_THREAD = "user_create_thread"
    const CMD_USER_COMMENT = "user_comment"
    const CMD_USER_CONFIRM_CLOSE = "user_confirm_close"
    const CMD_USER_RATE = "user_rate"
    const CMD_ADMIN_COMMENT = "admin_comment"
    const CMD_ADMIN_REQUEST_CLOSE = "admin_request_close"

    socket.on("connected", () => {
        console.log("connected")
        socket.emit("login", { admin: false })

    })

    socket.on('disconnect', () => {
        console.log('The user had left')
        // socket.emit("login", { admin: false })
    })

    socket.on("message", (packet) => {
        console.log("check socket")
        // const packet = JSON.parse(data)
        console.log(packet)


        switch (packet.event) {
            case CMD_ADMIN_COMMENT:
                console.log("admin reply")
                dispatch(actions.getDataAdminAddComment(packet))
                break;
            case CMD_ADMIN_REQUEST_CLOSE:
                console.log("admin request close")
                dispatch(actions.getDataAdminClose(packet))
                break;
            case CMD_USER_CREATE_THREAD:
                console.log("user create new thread")
                // dispatch(actions.getDataUserCreateNewThread(packet))
                break;
            case CMD_USER_COMMENT:
                console.log("user add 1 comment")
                // dispatch(actions.getDataUserAddComment(packet))
                break;
            case CMD_USER_CONFIRM_CLOSE:
                console.log("user confirm")
                // dispatch(actions.getDataUserConfirm(packet))
                break;
            case CMD_USER_RATE:
                console.log("usder rating")
                // dispatch(actions.getDataUserRate(packet))
                break;
        }
    })

    ws = {
        socket: socket
    }



    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )


}
