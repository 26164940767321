export const VERIFY_TOKEN_ENDPOINT = "/tokensignin"
export const GET_THREADS_ENDPOINT = "/sp/threads"
export const GET_ALL_COMMENT_ENDPOINT = "/sp/comments"
export const CREATE_NEW_THREAD_ENDPOINT = "/sp/threads/create"
export const GET_ALL_CATEGORY_ENDPOINT = "/sp/categories"
export const RATING_USER_ENDPOINT = "/sp/threads/rate"
export const ADD_COMMENT_ENDPOINT = "/sp/threads/comment"
export const CLOSE_THREAD_ENDPOINT = "/sp/threads/close"
export const GET_FAQ_ENDPOINT = "/sp/faqs"
export const THREAD_DETAIL_ENDPOINT = "/sp/threads/details"
export const GET_DETAIL_COMMENT_ENDPOINT = "/sp/comments/details";
export const GET_NOTIFY_ENDPOINT = "/sp/notifies";