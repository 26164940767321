import { ACTION_AUTHEN_VERIFY_TOKEN, ACTION_AUTHEN_VERIFY_TOKEN_ERROR, ACTION_AUTHEN_VERIFY_TOKEN_SUCCESS, ACTION_SIGNOUT } from "../actions"
const initState = {
    erro_msg: undefined,
    isLoading: false,
    isLoggined: undefined
}



export const verifyTokenReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_AUTHEN_VERIFY_TOKEN:
            return { ...state, isLoading: true, erro_msg: undefined, isLoggined: undefined}

        case ACTION_AUTHEN_VERIFY_TOKEN_SUCCESS:
            return { ...state, isLoading: false, isLoggined: true}

        case ACTION_AUTHEN_VERIFY_TOKEN_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg, isLoggined: false}
        case ACTION_SIGNOUT:
            return { ...state, isLoading: false, isLoggined: false }
        default:
            return state;
    }
}
