import { Button, Form } from 'react-bootstrap';
import { BsChevronLeft } from 'react-icons/bs';
import { ImAttachment } from 'react-icons/im';
import { Link, useParams } from 'react-router-dom';
import styles from './threadDetails.module.scss';
import { BsClock, BsCheck2All } from 'react-icons/bs'

import ImageViewer from 'react-simple-image-viewer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/store/actions';
import { cdn_url } from './domain';
import moment from 'moment';
import { FilesReview } from '../components/filesReview';
import { getBase64, randomString } from '../base';
import ReactStars from 'react-rating-stars-component';
import Loading from '../components/loading';
import imageCompression from 'browser-image-compression';

export const ThreadDetails = (props) => {

    const CMD_ADMIN_COMMENT = "admin_comment"
    const CMD_ADMIN_REQUEST_CLOSE = "admin_request_close"

    const [msgText, setMsgText] = useState("")
    const msgTextRef = useRef()
    const fileRef = useRef(null);
    const contentRef = useRef()

    const { tid } = useParams();

    const dispatch = useDispatch()

    const thread_detail = useSelector(state => state.threadDetail.data)
    const allComments = useSelector(state => state.comments.data)
    const addComment = useSelector(state => state.addComment.data)
    const isLoading = useSelector(state => state.threadDetail.isLoading || state.comments.isLoading || state.close.isLoading || state.rate.isLoading)


    const [localComments, setLocalComments] = useState({})
    const [currentImage, setCurrentImage] = useState(0);
    const [imagesView, setImagesView] = useState([])
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [showCommentForm, setShowCommentForm] = useState(false)
    const [selectedFile, setSelectedFile] = useState({});
    const [showReviewFiles, setShowReviewFiles] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showRating, setShowRating] = useState(false)
    const [isRated, setIsRated] = useState(false)


    const [starNumber, setStarNumber] = useState(0)
    const cid = useSelector(state => state.ws.cid)
    const commentDetail = useSelector(state => state.detailComment.data)
    const adminRequestCloseThreadId = useSelector(state => state.ws.tid)
    const event = useSelector(state => state.ws.event)
    const eventCounter = useSelector(state => state.ws.count)
    let auth = '';

    useEffect(() =>{
        console.log("start detail");
        auth = localStorage.getItem('auth');
        console.log(auth);
    })


    useEffect(() => {
        console.log("event change")
        if (event == CMD_ADMIN_REQUEST_CLOSE && adminRequestCloseThreadId == tid) {
            console.log(event)
            // setShowConfirm(true)
            setShowRating(true)
            setShowCommentForm(false)
        }
    }, [eventCounter])

    useEffect(() => {
        if (cid > 0) {
            console.log("change cid")
            console.log(cid)
            dispatch(actions.getDetailsComment(cid, auth))
        }
    }, [cid])

    useEffect(() => {
        console.log("get detail comment success")
        console.log(commentDetail)
        console.log(allComments)
        console.log(localComments)

        if (commentDetail !== undefined && commentDetail.tid == tid) {
            let date = moment().format("MMMM Do YYYY")
            if (localComments[date] == undefined) {
                localComments[date] = []
            }
            localComments[date] = [...localComments[date], { ...commentDetail, time: moment().format("HH:mm"), new: false, isSent: true }]
            setLocalComments(current => {
                const copy = { ...current }
                return copy
            })
        }
    }, [commentDetail])

    useEffect(() => {
        if (imagesView.length > 0)
            setIsViewerOpen(true);
    }, [imagesView])

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    useEffect(() => {

        if (thread_detail != undefined) {
            dispatch(actions.getAllComments(tid, auth))
            console.log(thread_detail)

            setIsRated(thread_detail.star > 0 ? true : false)
            if (thread_detail.star > 0 || thread_detail.closed) {
                setShowRating(true)
                setShowConfirm(false)
                setShowCommentForm(false)
            }else{
                setShowCommentForm(true)
                setShowRating(false)
                setShowConfirm(false)
            }
            //  else if (thread_detail.adminClose) {
            //     setShowConfirm(true)
            //     setShowRating(false)
            //     setShowCommentForm(false)
            // } else {
            //     setShowCommentForm(true)
            //     setShowRating(false)
            //     setShowConfirm(false)
            // }
        }
    }, [thread_detail])

    useEffect(() => {
        console.log(showCommentForm, showConfirm, showRating, isRated)
    }, [showCommentForm, showConfirm, showRating, isRated])

    useEffect(() => {
        console.log("allComments changes")
        if (allComments == undefined) return;
        let group = {}
        allComments.map((comment) => {
            let m = moment(new Date(comment.time))
            let date = m.format("MMMM Do YYYY")
            if (group[date] == undefined) {
                group[date] = []
            }
            group[date] = [...group[date], { ...comment, time: m.format("HH:mm") }]

        })
        setLocalComments(group)

    }, [allComments])


    useEffect(() => {
        if (addComment != undefined) {
            let date = moment(new Date(addComment.time)).format("MMMM Do YYYY")
            if (localComments[date] == undefined) {
                localComments[date] = []
            }

            let index = localComments[date].findIndex(obj => obj.request === addComment.request)
            let temp_state = [...localComments[date]];
            let temp_element = { ...temp_state[index] };
            temp_element.isSent = true
            temp_state[index] = temp_element

            setLocalComments(current => {
                const copy = { ...current }
                copy[date] = temp_state;
                return copy
            })
        }
    }, [addComment])

    useEffect(() => {
        contentRef?.current?.scrollTo({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
    }, [localComments])

    useEffect(() => {
        console.log("test xem")
        setIsRated(false)
        setShowRating(false)
        setShowConfirm(false)
        setShowCommentForm(false)
        dispatch(actions.getThreadDetail(tid, auth))

    }, [])





    const openImageViewer = useCallback((index, images) => {
        console.log(images)
        setCurrentImage(index);

        setImagesView(images.map((img) => img.length > 100 ? img : cdn_url + img))
    }, []);

    const handleFileChange = event => {
        console.log(event.target.files)
        let objs = {}
        console.log("check")
        Object.keys(event.target.files).map((key) => {
            
            getBase64(event.target.files[key]).then(f => {
                const options = {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 1280,
                    useWebWorker: true
                }

                imageCompression(event.target.files[key], options).then(file => {
                    imageCompression.getDataUrlFromFile(file).then(fNew => {
                        objs[key] = fNew
                        // arrImgName.push(event.target.files[key].name)
                        setSelectedFile({ ...selectedFile, ...objs })
                    })

                
                })
                // objs[key] = f
                // setSelectedFile({ ...selectedFile, ...objs })
            })
        })

        setShowReviewFiles(true)
        //to do

        // 👇️ reset file input
        // event.target.value = null;
    };

    const handleClick = () => {
        fileRef.current.click();
    }

    const handleConfirmation = (e, b) => {
        const confirm = {
            tid: tid,
            action: b
        }
        dispatch(actions.closeThread(confirm, auth))
        setShowConfirm(false)
        if (!b) {
            setShowCommentForm(true)
        } else {
            setShowRating(true)
        }

    }

    const closeReviewFiles = () => {
        setShowReviewFiles(false)
        setSelectedFile({})
    }
    const sendComment = (e) => {
        e.preventDefault()
        let arrImg = []
        if (Object.keys(selectedFile).length > 5) {
            alert("You are only allowed to upload a maximum of 5 files")
            return;
        }

        Object.keys(selectedFile).map(index => {
            arrImg.push(selectedFile[index])
        })
        if (msgText.trim() !== '' || arrImg.length > 0) {
            const newComment = {
                tid: tid,
                text: msgText,
                images: arrImg,
                request: randomString(16)
            }

            msgTextRef.current.focus()
            setMsgText("")
            if (showReviewFiles) {
                closeReviewFiles(false)
            }

            let date = moment().format("MMMM Do YYYY")
            if (localComments[date] == undefined) {
                localComments[date] = []

            }
            localComments[date] = [...localComments[date], { ...newComment, time: moment().format("HH:mm"), new: true }]

            dispatch(actions.addComment(newComment, auth));

        }
        if(msgText.trim() == ''){
            setMsgText("")
        }

    }

    const onRemoveReviewImage = (index) => {
        setSelectedFile(current => {
            const copy = { ...current }
            delete copy[index];
            return copy
        })
    }

    const ratingChanged = (newRating) => {
        setStarNumber(newRating)
        setIsRated(true)

        dispatch(actions.rate(tid, newRating, auth))
    };


    return (
        <div className={styles.thread_details}>
            <FilesReview
                onRemoveImage={onRemoveReviewImage}
                sendComment={sendComment}
                show={showReviewFiles}
                files={selectedFile}
                text={msgText}
                onClose={() => closeReviewFiles()}
                onTextChange={(e) => setMsgText(e.target.value)} />
            {isViewerOpen && (
                <ImageViewer
                    src={imagesView}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
            {thread_detail &&
                <>
                    <div className="breakcum">
                        <Link to={'/'}><BsChevronLeft className="backIcon" /></Link>
                        <div className={"category-tag"}>{thread_detail.categories.length > 0 ? thread_detail.categories[0] : ""}</div>
                        <div className='threadID'>#{thread_detail.id}</div>
                    </div>
                    <div className='content' ref={contentRef}>
                        <div className='question'>
                            <div className={'title'}>{thread_detail.text}</div>
                            <div className='thumb'>
                                {thread_detail.images.map((src, index) => (
                                    <img
                                        src={cdn_url + src}
                                        onClick={() => openImageViewer(index, thread_detail.images)}
                                        width="300"
                                        key={index}
                                        style={{ margin: '2px' }}
                                        alt=""
                                    />
                                ))}
                            </div>
                        </div>
                        <div className='comments'>
                            {Object.keys(localComments).map((key, index) => {



                                return (
                                    <div key={key + index}><div className='date'>{key}</div>
                                        {
                                            localComments[key].map((comment, ind) => {
                                                return (
                                                    <div className='item' key={ind}>
                                                        <div className='name'>{comment.mid > 0 ? "Admin" : "You"}</div>
                                                        <div className='msg'>
                                                            <div className='left'>
                                                                {comment.text.trim().length > 0 && <div className={comment.mid > 0 ? "text admin" : "text user"}>{comment.text}</div>}
                                                                <div className='thumb'>
                                                                    {comment.images.map((src, index) => (
                                                                        <img
                                                                            src={comment.new ? src : cdn_url + src}
                                                                            onClick={() => openImageViewer(index, comment.images)}
                                                                            width="300"
                                                                            key={index}
                                                                            style={{ margin: '2px' }}
                                                                            alt=""
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>

                                                            <div className='time'>{comment.isSent ? <BsCheck2All className='checked' /> : <BsClock />}{comment.time}</div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )

                            })}

                        </div>
                    </div>{/** END CONTENT */}

                    <div className='action'>
                        {showConfirm && <div className="confirm">
                            <div className="text">
                                Admin has marked this conversation as closed. Do you really want to close this conversation?
                            </div>
                            <div className="buttons">

                                <Button className='deny' onClick={(e) => handleConfirmation(e, false)}>No</Button>
                                <Button className='accept' onClick={(e) => handleConfirmation(e, true)}>Yes</Button>
                            </div>
                        </div>}
                        {showCommentForm && <div className='comment-form'>
                            {/* <Form.Control className="inputtext" type="text" maxLength={255} placeholder="Add a comment..." ></Form.Control> */}
                            <div className="input-group">
                                <input placeholder='Add a comment...' onKeyUp={(event) => { if (event.key === "Enter") sendComment(event) }} ref={msgTextRef} value={msgText} onChange={(e) => setMsgText(e.target.value)} maxLength={255} type="text" className="form-control input-text" aria-label="Amount (to the nearest dollar)" />
                                <div className="input-group-append">
                                    <span className="input-group-text">{msgText.length} / 255</span>
                                </div>
                            </div>
                            <ImAttachment className="btnattach" onClick={handleClick} />
                            <input type="file" style={{ display: "none" }} multiple accept="image/x-png,image/gif,image/jpeg" ref={fileRef} onChange={handleFileChange} />
                            <Button onClick={sendComment} className="btncomment" >Add Comment</Button>
                        </div>}



                        {showRating && <div className="ratingForm">
                            {!isRated && <div className="rateText">How is the quality of our support? Please help us to rate below</div>}
                            {!isRated && <ReactStars disable={false} classNames="stars"
                                count={5}
                                onChange={ratingChanged}
                                size={50}
                                activeColor="#ffd700"
                                value={thread_detail.star}
                                edit={true}

                            />}
                            {isRated && <ReactStars disable={false} classNames="stars"
                                count={5}
                                size={50}
                                activeColor="#ffd700"
                                value={thread_detail.star > 0 ? thread_detail.star : starNumber}
                                edit={false}

                            />}
                        </div>}

                        {isLoading &&
                            <Loading />
                        }

                    </div>
                </>
            }

        </div>
    )
}
