import {ACTION_GET_ALL_FAQ, ACTION_GET_ALL_FAQ_SUCCESS, ACTION_GET_ALL_FAQ_ERROR} from '../actions'

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const getAllFaqsReducer = (state = initState, action = {}) => {
    switch(action.type){
        case ACTION_GET_ALL_FAQ:
            return {...state, isLoading: true, erro_msg: undefined}

        case ACTION_GET_ALL_FAQ_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data, erro_msg: undefined}

        case ACTION_GET_ALL_FAQ_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }

        default:
            return state;
    }
}