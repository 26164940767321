import { ACTION_USER_RATE, ACTION_USER_ADD_COMMENT, ACTION_USER_CREATE_THREAD, ACTION_USER_CONFIRM_CLOSE, ACTION_ADMIN_REQUEST_CLOSE, ACTION_ADMIN_ADD_COMMENT } from "../actions";

const initState = {
    event: undefined,
    status: undefined,
    star: 0,
    tid: 0, 
    cid: 0,
    uid: 0,
    count: 0,
}


export const websocketReducer = (state = initState, action = {}) => {
    switch(action.type){
        case ACTION_USER_CREATE_THREAD:
            // return {...state, }

        case ACTION_USER_ADD_COMMENT:
            return{...state, cid: action.payload.data.cid, uid: action.payload.data.uid, event: action.payload.data.event, count: state.count + 1}

        case ACTION_USER_CONFIRM_CLOSE:
            return{...state, status: action.payload.data.Status, tid: action.payload.data.tid, event: action.payload.data.event, count: state.count + 1}

        case ACTION_USER_RATE:
            return{...state, star: action.payload.data.star, tid: action.payload.data.tid, event: action.payload.data.event, count: state.count + 1}

        case ACTION_ADMIN_REQUEST_CLOSE:
            return {...state, tid: action.payload.data.tid, uid: action.payload.data.uid, event: action.payload.data.event, status: undefined, star: 0, cid: 0, count: state.count + 1 }

        case ACTION_ADMIN_ADD_COMMENT:
            return {...state, cid: action.payload.data.cid, uid: action.payload.data.uid, event: action.payload.data.event, status: undefined, star: 0, tid: action.payload.data.tid, count: state.count + 1 }

        default:
            return state;
    }
}