import { combineReducers } from 'redux';
  
import { verifyTokenReducer } from './verify-token';
import { getThreadsReducer } from './getThreads';
import {getAllCommentsReducer} from './getComment';
import { createNewThreadReducer } from './createNewThread';
import { getCategoriesReducer } from './getCategories';
import { ratingReducer } from './ratingUser';
import { addCommentReducer } from './addComment';
import { closeReducer } from './close';
import { getAllFaqsReducer } from './getFaq';
import { getThreadDetailReducer } from './detailThread';
import { detailCommentReducer } from './getDetailComment';
import { websocketReducer } from './ws';
import { getNotifiesReducer } from './getnotify';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const authPersistConfig = {
    key: "auth",
    storage: storage,
    whitelist: ["isLoggined"]
}

export default combineReducers({
    token: persistReducer(authPersistConfig, verifyTokenReducer),
    threads: getThreadsReducer,
    comments: getAllCommentsReducer,
    create: createNewThreadReducer,
    categories: getCategoriesReducer,
    rate: ratingReducer,
    addComment: addCommentReducer,
    close: closeReducer,
    allFaqs: getAllFaqsReducer,
    threadDetail: getThreadDetailReducer,
    detailComment: detailCommentReducer,
    notify: getNotifiesReducer,
    ws: websocketReducer

});