import axios from "axios"
import { ACTION_AUTHEN_VERIFY_TOKEN, ACTION_AUTHEN_VERIFY_TOKEN_ERROR, ACTION_AUTHEN_VERIFY_TOKEN_SUCCESS } from "."
import * as api from "../../../api"
import { logout } from './logout';
export const verifyTokenRequest = (access_token) => ({
    type: ACTION_AUTHEN_VERIFY_TOKEN,
    payload: {
        token: access_token
    }
})

export const verifyTokenSuccess = data => ({
    type: ACTION_AUTHEN_VERIFY_TOKEN_SUCCESS,
    payload: {
        data
    }
})


export const verifyTokenError = msg => ({
    type: ACTION_AUTHEN_VERIFY_TOKEN_ERROR,
    payload: {
        msg
    }
})


export const verifyToken = (access_token) => {
    return dispatch => {
        dispatch(verifyTokenRequest(access_token))

        api.verifyToken(access_token)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(verifyTokenError("error"));
                    return
                }
                dispatch(verifyTokenSuccess(access_token))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(verifyTokenError("system error"))
            });
    }
}

