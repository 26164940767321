import axios from "axios";
import {ACTION_RATING_USER, ACTION_RATING_USER_ERROR, ACTION_RATING_USER_SUCCESS} from '.'
import * as api from '../../../api'
import { logout } from './logout';

export const rateRequest = () => ({
    type: ACTION_RATING_USER,
    payload: {
    }
})

export const rateSuccess = data => ({
    type: ACTION_RATING_USER_SUCCESS,
    payload: {
        data
    }
})


export const rateError = msg => ({
    type: ACTION_RATING_USER_ERROR,
    payload: {
        msg
    }
})

export const rate = (tid, star, auth) => {
    console.log("check")
    return dispatch => {
        dispatch(rateRequest())

        api.rating(tid, star, auth)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(rateError("error"));
                    return
                }
                dispatch(rateSuccess(data))
                console.log("success")
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(rateError("system error"))
            });
    }
}