import axios from "axios"
import { logout } from './logout';
import { ACTION_GET_THREAD_DETAIL, ACTION_GET_THREAD_DETAIL_SUCCESS, ACTION_GET_THREAD_DETAIL_ERROR } from "."
import * as api from "../../../api";
export const getThreadDetailRequest = () => ({
    type: ACTION_GET_THREAD_DETAIL,
    payload: {
    }
})

export const getThreadDetailSuccess = data => ({
    type: ACTION_GET_THREAD_DETAIL_SUCCESS,
    payload: {
        data
    }
})


export const getThreadDetailError = msg => ({
    type: ACTION_GET_THREAD_DETAIL_ERROR,
    payload: {
        msg
    }
})


export const getThreadDetail = (tid, auth) => {
    return dispatch => {
        dispatch(getThreadDetailRequest())

        api.getThreadDetail(tid, auth)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getThreadDetailError("error"));
                    return
                }
                dispatch(getThreadDetailSuccess(data.thread))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getThreadDetailError("system error"))
            });
    }
}

