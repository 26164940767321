import React from "react";
import Container from "react-bootstrap/Container";
import { BsChevronLeft } from 'react-icons/bs';
import styles from './header.module.css';
import { Link, useHistory } from "react-router-dom";

export default function Header() {
    const history = useHistory();
    return (
        < div >
            <div className={styles.homeMenutop}>
                <Link to={'/'}><BsChevronLeft className={styles.icon} /></Link>
                <a className={styles.content} href="#">VIP Support</a>
            </div>
        </div >
    )
}