import axios from "axios";

export const anonRequest = (_data, auth, url) => {
    let data = JSON.stringify(_data);
    // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzMwNjYwMDksInVpZCI6MTB9.P6FkYYxj1IYjcIvSROC3b398vTHyNGG9EhftMjvYKXo";
    console.log("checkpasstoken: "+auth);
    let config = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+auth
        },
        data: data
    };
    return axios(config);
}