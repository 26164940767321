import axios from "axios"
import { ACTION_GET_CATEGORIES, ACTION_GET_CATEGORIES_SUCCESS, ACTION_GET_CATEGORIES_ERROR } from "."
import * as api from "../../../api";
import { logout } from "./logout";

export const getCategoriesRequest = () => ({
    type: ACTION_GET_CATEGORIES,
    payload: {
    }
})

export const getCategoriesSuccess = data => ({
    type: ACTION_GET_CATEGORIES_SUCCESS,
    payload: {
        data
    }
})


export const getCategoriesError = msg => ({
    type: ACTION_GET_CATEGORIES_ERROR,
    payload: {
        msg
    }
})

export const getCategories = (auth) => {
    return dispatch => {
        dispatch(getCategoriesRequest())

        api.getAllCategories(auth)
           .then(({data}) => {
               if(data.result.code !==0){
                   dispatch(getCategoriesError("error"));
                   return
               }
               dispatch(getCategoriesSuccess(data.list))
           })
           .catch((err) => {
               console.log(err);
               if (err.response) {
                if (err.response.status === 401) {
                    dispatch(logout())
                    return
                }
            }
               dispatch(getCategoriesError("system error"))
           })
    }
}