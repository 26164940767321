import { ACTION_ADD_COMMENT, ACTION_ADD_COMMENT_ERROR, ACTION_ADD_COMMENT_SUCCESS} from '../actions'

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const addCommentReducer = (state = initState, action = {}) => {
    switch(action.type){
        case ACTION_ADD_COMMENT:
            return {...state, isLoading: true, erro_msg: undefined}

        case ACTION_ADD_COMMENT_SUCCESS:
            console.log(action)
            return { ...state, isLoading: false, data: action.payload.data, erro_msg: undefined}

        case ACTION_ADD_COMMENT_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }

        default:
            return state;
    }
}
