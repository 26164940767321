import {ACTION_RATING_USER, ACTION_RATING_USER_SUCCESS, ACTION_RATING_USER_ERROR} from '../actions'

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const ratingReducer = (state = initState, action = {}) => {
    switch(action.type){
        case ACTION_RATING_USER:
            return {...state, isLoading: true, erro_msg: undefined}

        case ACTION_RATING_USER_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data, erro_msg: undefined}

        case ACTION_RATING_USER_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }

        default:
            return state;
    }
}