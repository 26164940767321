import axios from "axios"
import { ACTION_CREATE_NEW_THREAD, ACTION_CREATE_NEW_THREAD_SUCCESS, ACTION_CREATE_NEW_THREAD_ERROR } from "."
import * as api from "../../../api";
import { logout } from "./logout";

export const createNewThreadRequest = () => ({
    type: ACTION_CREATE_NEW_THREAD,
    payload: {
    }
})

export const createNewThreadSuccess = data => ({
    type: ACTION_CREATE_NEW_THREAD_SUCCESS,
    payload: {
        data
    }
})


export const createNewThreadError = msg => ({
    type: ACTION_CREATE_NEW_THREAD_ERROR,
    payload: {
        msg
    }
})

export const createNewThread = (newThread, auth) => {
    return dispatch => {
        dispatch(createNewThreadRequest())
        api.createNewThread(newThread, auth)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(createNewThreadError("error"));
                    return
                }
                dispatch(createNewThreadSuccess(data.tid))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(createNewThreadError("system error"))
            });
    }
}



