import React, { useState, useEffect, useRef, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Header from "../components/header";
import styles from './createNewThread.module.css';
import { Form, Row, Col, Modal } from "react-bootstrap";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { TiDelete } from 'react-icons/ti';
import 'typeface-quicksand';
import { useDispatch, useSelector } from 'react-redux';
import * as getCategoriesAction from '../redux/store/actions';
import * as getThreadsAction from '../redux/store/actions';
import * as createAction from '../redux/store/actions';
import * as sendMessage from '../redux/store/actions';
import { cdn_url } from './domain';
import { getBase64 } from "../base";
import Loading from '../components/loading';
import ImageViewer from 'react-simple-image-viewer';
import imageCompression from 'browser-image-compression';
import socketIOClient from 'socket.io-client';

const CreateNewThread = ({ location }) => {


    const dispatch = useDispatch();
    const [listImages, setListImages] = useState([]);
    const [inputLength, setInputLength] = useState(0);
    const arrImgName = []
    const [listFile, setListFile] = useState([]);
    const [isError, setIsError] = useState(false);
    const [showImage, setShowImage] = useState(false)
    const history = useHistory();
    const [isCreated, setIsCreated] = useState(false);
    const [imageShowUrl, setImageShowUrl] = useState("")
    const isLoading = useSelector(state => state.create.isLoading || state.categories.isLoading)
    const [isCompress, setIsCompress] = useState(false)
    const [currentImage, setCurrentImage] = useState(0);
    const [imagesView, setImagesView] = useState([])
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const threads = useSelector(state => state.threads.data)
    const categories = useSelector(state => state.categories.data)
    const tid = useSelector(state => state.create.data)
    const { state } = useLocation();

    const err_msg = useSelector(state => state.create.erro_msg)
    const getDetail_err_msg = useSelector(state => state.threadDetail.erro_msg)
    const descRef = useRef();
    const cateRef = useRef();
    const fileRef = useRef(null);

    const [isFilePicked, setIsFilePicked] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const thread_detail = useSelector(state => state.threadDetail.data)

    const isCreatedLoading = useSelector(state => state.create.isLoading)
    const [allThreads, setAllThreads] = useState()
    const [description, setDescription] = useState('')
    const [cate, setCate] = useState('')
    const [desc, setDesc] = useState('')
    // let maxThreadId = 0;
    const [lastThread, setLastThread] = useState();
    const [lastTime, setLastTime] = useState(0);
    const [isSpam, setIsSpam] = useState(false);
    let auth = '';
    let adminPage = process.env.REACT_APP_ADMIN_URL + `${tid}`;

    useEffect(() =>{
        console.log('start create');
        auth = localStorage.getItem('auth');
        console.log(auth);
    },[])


    useEffect(() => {
        console.log("redirect create page success!")
        fetchCategories()
        fetchThreads()
    }, [])

    useEffect(() =>{
        if(threads != undefined){
            setAllThreads(threads);
        }
    }, [threads])

    useEffect(() => {
        if(allThreads != undefined){
            console.log(allThreads);
            // allThreads.forEach(thread => {
            //     if(thread.id > maxThreadId){
            //         maxThreadId = thread.id;
            //     }
            // })
            setLastThread(allThreads[allThreads.length - 1]);
        }
    }, [allThreads])

    useEffect(() =>{
        if(lastThread != undefined){
            console.log(lastThread);
            setLastTime(lastThread.time);
        }
    },[lastThread])

    useEffect(() => {
        if(lastTime != 0){
            console.log(lastTime);
        }
    },[lastTime])

    useEffect(() => {
        if (!isCreatedLoading && tid != undefined) {
            console.log("send api telegram");
            fetchTelegramAPI()
            //redicrect
            setIsCreated(true)
        }

    }, [isCreatedLoading])

    useEffect(() => {
        if (imagesView.length > 0)
            setIsViewerOpen(true);
    }, [imagesView])


    const handleClose = () => {
        if (showImage == true) {
            setShowImage(false);
        }
    }

    const handleCloseSpam = () => {
        if(isSpam == true){
            setIsSpam(false);
        }
    }
    // const handleShow = (name) => {
    //     console.log(Object.keys(selectedFile))
    //     setImageShowUrl(selectedFile[name])
    //     setShowImage(true)

    // }
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index)
        let arrImg = []
        Object.keys(selectedFile).map(index => {
            console.log(index)
            arrImg.push(selectedFile[index])
        })
        setImagesView(arrImg)

    })
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const fetchTelegramAPI = async () => {
        let message = `There is a new thread, ${adminPage}`;
        dispatch(sendMessage.sendTelegramNotification(message))
    }

    const fetchThreads = async () => {
        dispatch(getThreadsAction.getThreads(true, auth))
    }

    const fetchCategories = async () => {
        dispatch(getCategoriesAction.getCategories(auth))
    }
    const onTextChange = (e) => {
        setInputLength(e.target.value.length)
    }

    const submitValue = () => {
        const thread = {
            'category': cate,
            'description': description
        }
    }


    function createHandler(e) {
        e.preventDefault();
        console.log(selectedFile)
        let arrImg = []
        if (Object.keys(selectedFile).length > 5) {
            alert("You are only allowed to upload a maximum of 5 files")
        } else {
            Object.keys(selectedFile).map(index => {
                console.log(index)
                arrImg.push(selectedFile[index].substring(selectedFile[index].indexOf('base64,') + 7))
            })
            setCate(cateRef.current.value)
            setDesc(descRef.current.value)

            const newThread = {
                title: "My Thread",
                text: descRef.current.value,
                categories: [cateRef.current.value],
                images: arrImg
            }
            console.log(newThread)

            const checkSpamTime = 5*60*1000;
            const dateNow = new Date();
            let timeNow = dateNow.getTime();
            console.log(timeNow);

            console.log(lastTime)
            const lastDate = new Date(lastTime);
            let timeLast = lastDate.getTime();
            console.log(timeLast);
            if(timeNow - timeLast < checkSpamTime){
                console.log("spam");
                setIsSpam(true);
                return;
            }

            auth = localStorage.getItem('auth');
            if (newThread.text.trim() !== '') {
                console.log('authen='+auth)
                dispatch(createAction.createNewThread(newThread, auth))
                descRef.current.value = ('')
                setSelectedFile('')
            } else {
                alert("Please fill description")
            }

        }

    }

    function remove(name) {
        setSelectedFile(current => {
            const copy = { ...current }
            delete copy[name]
            return copy
        })
    }

    const handleClick = (e) => {
        e.preventDefault();
        console.log("click attack file");

        fileRef.current.click();
    }
    const handleFileChange = event => {
        console.log(event)
        let objs = {}
        setIsCompress(true)
        Object.keys(event.target.files).map((key) => {

            getBase64(event.target.files[key]).then(f => {
                const options = {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 1280,
                    useWebWorker: true
                }
                imageCompression(event.target.files[key], options).then(file => {
                    imageCompression.getDataUrlFromFile(file).then(f => {
                        objs[event.target.files[key].name] = f
                        arrImgName.push(event.target.files[key].name)
                        setSelectedFile({ ...selectedFile, ...objs })
                    })

                })
                // objs[event.target.files[key].name] = f
                // arrImgName.push(event.target.files[key].name)
                // setSelectedFile({ ...selectedFile, ...objs })

            })
        })
        console.log(event.target.files)
        setIsFilePicked(true);

    };

    if (isCreated) {
        // setIsCreated(false)
        return (
            // <Redirect to={{ pathname: "/thread_detail", state: { id: tid, cate: cate, detail: desc, starNumber: 0, listImg: thread_detail.images, isAdminClosed: false, isUserClosed: false } }} />
            <Redirect to={{ pathname: "/thread_detail/" + tid, state: { id: tid } }} />

        )

    } else {
        return (
            <>
                {isViewerOpen && (
                    <ImageViewer
                        src={imagesView}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                )}
                <Modal
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg" className={styles.show_image} show={showImage} onHide={handleClose} onClick={handleClose}>
                    <Modal.Body style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
                        {imageShowUrl != "" &&
                            <img src={imageShowUrl} alt="" />
                        }
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={isSpam} onHide={handleCloseSpam} onClick={handleCloseSpam}> 
                    <Modal.Header>
                        <Modal.Title>You are only allowed to submit thread every 5 minute!</Modal.Title>
                    </Modal.Header>
                </Modal>
                {/* {showImage && <div className={styles.show_image} onHide={handleClose} onClick={handleClose} >
                    {imageShowUrl != "" &&
                        <img src={imageShowUrl} alt="" />
                    }
                </div>} */}
                <Header />
                <form className="formFields">
                    <div className={styles.formField}>
                        <label className={styles.formFieldLabel} htmlFor="name">Category&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <select ref={cateRef}  className={styles.formFieldInputCategory}>
                            {categories != undefined && categories.map((cate) => (
                                <option value={cate.id} name="cate" key={cate.id}>{cate.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.formField}>
                        <label className={styles.formFieldLabel} htmlFor="name">Description</label>
                        <textarea
                            type="text"
                            rows="5"
                            name="desc"
                            ref={descRef}
                            onChange={onTextChange}
                            maxLength="255"
                            className={styles.formFieldInputDescription}
                        ></textarea>
                        {/* <span className={styles.count}>{inputLength}/255</span> */}
                    </div>
                    <div className={styles.formFieldCount}>
                        <label className={styles.formFieldLabel} htmlFor="name"></label>
                        <span className={styles.count}>{inputLength}/255</span>
                    </div>
                </form>


                <div className={styles.attack}>
                    <div className={styles.attachment}>
                        {/* <input type="button" value=" + Attack file" /> */}
                        <button className={styles.attachmentBtn} onClick={handleClick}> + Attack file</button>
                        <input type="file" style={{ display: "none" }} multiple accept="image/x-png,image/gif,image/jpeg" ref={fileRef} onChange={handleFileChange} className={styles.attachmentBtn} />
                    </div>
                    <div className={styles.file}>
                        <ul class={styles.listImg}>
                            {Object.keys(selectedFile).map((fileName, index) => (
                                <li class={styles.item}>
                                    <a href="#" className={styles.image} onClick={() => openImageViewer(index)} >{fileName}</a><TiDelete onClick={(e) => remove(fileName)} className={styles.iconRemove} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={styles.create}>
                    <button className={styles.btnCreate} onClick={(e) => { createHandler(e) }}>Create new thread</button>
                </div>
                {isLoading &&
                    <Loading />
                }
                {/* {isCompress && <Loading />} */}
            </ >
        )
    }
}
export default CreateNewThread;