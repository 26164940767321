import axios from "axios"
import { logout } from './logout';
import { ACTION_GET_THREADS_SUCCESS, ACTION_GET_THREADS_ERROR, ACTION_GET_THREADS } from "."
import * as api from "../../../api";
export const getThreadsRequest = () => ({
    type: ACTION_GET_THREADS,
    payload: {
    }
})

export const getThreadsSuccess = data => ({
    type: ACTION_GET_THREADS_SUCCESS,
    payload: {
        data
    }
})


export const getThreadsError = msg => ({
    type: ACTION_GET_THREADS_ERROR,
    payload: {
        msg
    }
})


export const getThreads = (includeClosed, auth) => {
    return dispatch => {
        dispatch(getThreadsRequest())

        api.getThreads(includeClosed, auth)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(getThreadsError("error"));
                    return
                }
                dispatch(getThreadsSuccess(data.list))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(getThreadsError("system error"))
            });
    }
}

