export * from "./token-login"
export * from "./get-threads"
export * from "./get-comment"
export * from "./create-newThread"
export * from "./get-categories"
export * from "./rating-user"
export * from "./add-comment"
export * from "./close"
export * from "./getFaq"
export * from './thread_detail'
export * from './getDetailComment'
export * from './ws'
export * from './getnotifi'
export * from './sendMessageTelegram'

export const ACTION_AUTHEN_VERIFY_TOKEN = "AUTHEN_VERIFY_TOKEN";
export const ACTION_AUTHEN_VERIFY_TOKEN_SUCCESS = "AUTHEN_VERIFY_TOKEN_SUCCESS";
export const ACTION_AUTHEN_VERIFY_TOKEN_ERROR = "AUTHEN_VERIFY_TOKEN_ERROR";
export const ACTION_SIGNOUT = "SIGNOUT";

export const ACTION_GET_THREADS = "GET_THREADS";
export const ACTION_GET_THREADS_SUCCESS = "GET_THREADS_SUCCESS";
export const ACTION_GET_THREADS_ERROR = "GET_THREADS_ERROR";

export const ACTION_GET_THREAD_DETAIL = "GET_THREAD_DETAIL";
export const ACTION_GET_THREAD_DETAIL_SUCCESS = "GET_THREAD_DETAIL_SUCCESS";
export const ACTION_GET_THREAD_DETAIL_ERROR = "GET_THREAD_DETAIL_ERROR";

export const ACTION_GET_COMMENT = "GET_COMMENT";
export const ACTION_GET_COMMENT_SUCCESS = "GET_COMMENT_SUCCESS";
export const ACTION_GET_COMMENT_ERROR = "GET_COMMENT_ERROR";
export const ACTION_UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";

export const ACTION_CREATE_NEW_THREAD = "CREATE_NEW_THREAD";
export const ACTION_CREATE_NEW_THREAD_SUCCESS = "CREATE_NEW_THREAD_SUCCESS";
export const ACTION_CREATE_NEW_THREAD_ERROR = "CREATE_NEW_THREAD_ERROR";

export const ACTION_GET_CATEGORIES = "GET_CATEGORIES";
export const ACTION_GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const ACTION_GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";

export const ACTION_GET_ALL_FAQ = "GET_ALL_FAQ";
export const ACTION_GET_ALL_FAQ_SUCCESS = "GET_ALL_FAQ_SUCCESS";
export const ACTION_GET_ALL_FAQ_ERROR = "GET_ALL_FAQ_ERROR";

export const ACTION_RATING_USER = "RATING_USER";
export const ACTION_RATING_USER_SUCCESS = "RATING_USER_SUCCESS";
export const ACTION_RATING_USER_ERROR = "RATING_USER_ERROR";

export const ACTION_ADD_COMMENT = "ADD_COMMENT";
export const ACTION_ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ACTION_ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";

export const ACTION_CLOSE_THREAD = "CLOSE_THREAD";
export const ACTION_CLOSE_THREAD_SUCCESS = "CLOSE_THREAD_SUCCESS";
export const ACTION_CLOSE_THREAD_ERROR = "CLOSE_THREAD_ERROR";

export const CMD_ADMIN_COMMENT = "admin_comment"
export const CMD_ADMIN_REQUEST_CLOSE = "admin_request_close"
export const CMD_USER_COMMENT = "user_comment"
export const CMD_USER_CONFIRM_CLOSE = "user_confirm_close"
export const CMD_USER_RATE = "user_rate"

export const ACTION_USER_CREATE_THREAD = "USER_CREATE_THREAD";
export const ACTION_USER_ADD_COMMENT = "USER_ADD_COMMENT";
export const ACTION_USER_CONFIRM_CLOSE = "USER_CONFIRM_CLOSE";
export const ACTION_USER_RATE = "USER_RATE";
export const ACTION_ADMIN_ADD_COMMENT = "ADMIN_ADD_COMMENT";
export const ACTION_ADMIN_REQUEST_CLOSE = "ADMIN_REQUEST_CLOSE";

export const ACTION_GET_DETAIL_COMMENT = "GET_DETAIL_COMMENT";
export const ACTION_GET_DETAIL_COMMENT_SUCCESS = "GET_DETAIL_COMMENT_SUCCESS";
export const ACTION_GET_DETAIL_COMMENT_ERROR = "GET_DETAIL_COMMENT_ERROR";

export const ACTION_GET_NOTIFY = "GET_NOTIFY";
export const ACTION_GET_NOTIFY_SUCCESS = "GET_NOTIFY_SUCCESS";
export const ACTION_GET_NOTIFY_ERROR = "GET_NOTIFY_ERROR";
export const ACTION_ADD_NOTIFY = "ADD_NOTIFY";
export const ACTION_DELETE_NOTIFY = "DELETE_NOTIFY";

export const ACTION_SEND_TELEGRAM_MESSAGE = "SEND_TELEGRAM_MESSAGE";