import axios from "axios"
import { ACTION_GET_ALL_FAQ, ACTION_GET_ALL_FAQ_SUCCESS, ACTION_GET_ALL_FAQ_ERROR } from "."
import * as api from "../../../api";
import { logout } from "./logout";

export const getAllFaqRequest = () => ({
    type: ACTION_GET_ALL_FAQ,
    payload: {}
})

export const getAllFaqSuccess = data => ({
    type: ACTION_GET_ALL_FAQ_SUCCESS,
    payload: {data}
})

export const getAllFaqError = msg => ({
    type: ACTION_GET_ALL_FAQ_ERROR,
    payload: {msg}
})

export const getAllFaq = (auth) => {
    return dispatch => {
        dispatch(getAllFaqRequest())

        api.getAllFaq(auth)
           .then(({data}) => {
               if(data.result.code !==0){
                   dispatch(getAllFaqError("error"));
                   return
               }
               dispatch(getAllFaqSuccess(data.lists))
           })
           .catch((err) => {
               console.log(err);
               if (err.response) {
                if (err.response.status === 401) {
                    dispatch(logout())
                    return
                }
            }
               dispatch(getAllFaqError("system error"))
           })
    }
}