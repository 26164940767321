import axios from "axios";
import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import { Link, Redirect, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as authAction from '../redux/store/actions';
import UnAuthen from "./unAuthen";


const Login = () => {
    let { token } = useParams();

    const dispatch = useDispatch();
    const [checklogin, setChecklogin] = useState();

    const fetchToken = async () => {
        dispatch(authAction.verifyToken(token))
    }
    const isLoggin = useSelector(state => state.token.isLoggined)
    let auth = localStorage.getItem('auth');
    useEffect(() => {
        localStorage.setItem('auth', token);
        if(isLoggin != true){
            fetchToken()
        }
    }, [])

    useEffect(() =>{
        console.log(isLoggin)
        if(isLoggin != undefined){
            setChecklogin(isLoggin);
        }
    }, [isLoggin])
    
    if(checklogin != undefined){
        return (
            <>
            {checklogin == true && <Redirect to={"/"} />}
            {checklogin == false && <UnAuthen />}
            </>
        )
    }
    

    // if (checklogin){
    //     return (
    //         <Redirect to={"/"} />
    //     )
    // }
    // else{
    //     return (
    //         <UnAuthen />
    //     )
    // }


    // if (isLoggin){
    //     return (
    //         <Redirect to={"/"} />
    //     )
    // }
    // else{
    //     return (
    //         <UnAuthen />
    //     )
    // }
    
    
    // return (
    //     <div>
            
    //     </div>
    // );

}
export default Login






// export default function Login() {
//     let api_url = configData.URL;
//     const [auth, setAuth] = useState('')
//     let { token } = useParams();

//     const Secure = async () => {
//         const instance = axios.create({
//             timeout: 1000,
//         })

//         let credentials = {
//             "token": token
//         }
//         try {

//             const { data } = await instance.post( `/tokensignin`, credentials);
//             console.log(data)
//             setAuth(JSON.stringify(data))
//             console.log(auth)
//             console.log(token)
//         } catch (err) {
//             console.log(err)
//         }
//     }

//     useEffect(() => {
//         Secure()
//     }, [])

//     return (
//         <div>
//             <Container fluid={true}>
//             <h1><Link to={"/"}><button>WEB</button></Link></h1>
//             </Container>
//         </div>
//     )
// }