import axios from "axios";
import { ACTION_ADD_COMMENT, ACTION_ADD_COMMENT_ERROR, ACTION_ADD_COMMENT_SUCCESS, ACTION_UPDATE_COMMENT_SUCCESS, ACTION_GET_COMMENT_SUCCESS } from '.'
import * as api from '../../../api'
import { logout } from "./logout";

export const addCommentRequest = (newComment) => ({
    type: ACTION_ADD_COMMENT,
    payload: {
        newComment: newComment
    }
})

export const addCommentSuccess = data => ({
    type: ACTION_ADD_COMMENT_SUCCESS,
    payload: {
        data
    }
})

// export const updateCommentSuccess = data => ({
//     type: ACTION_UPDATE_COMMENT_SUCCESS,
//     payload: {
//         data
//     }
// })


export const addCommentError = msg => ({
    type: ACTION_ADD_COMMENT_ERROR,
    payload: {
        msg
    }
})


export const addComment = (newComment, auth) => {
    return dispatch => {
        dispatch(addCommentRequest(newComment))

        let imgs = []
        let oriImgs = newComment.images
        oriImgs.map(img => {
            imgs.push(img.substring(img.indexOf('base64,') + 7))
        })
        newComment.images = imgs

        api.addComment(newComment, auth)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(addCommentError("error"));
                    return
                }

                // const newState = {...newComment, time: Date().toLocaleString(), uid: 1, imgbase64: true, images: oriImgs}
                dispatch(addCommentSuccess(data.Comment))

            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(addCommentError("system error"))
            });
    }
}

