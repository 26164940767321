import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import UnAuthen from "./unAuthen";
import * as authAction from '../redux/store/actions';
import socketIOClient from 'socket.io-client';
import { socketURL } from '../base';

export const SecureScreen = (props) => {

    const dispatch = useDispatch();
    let { token } = useParams();
    const fetchToken = async () => {
        dispatch(authAction.verifyToken(token))
    }
    const { component } = props
    const isLoggin = useSelector(state => state.token.isLoggined)

    useEffect(() => {
        if (!isLoggin ) {
            fetchToken()
        }
    }, [])





    return (
        <>
            {isLoggin ? <>{component}</> : <UnAuthen />}
        </>
    )
}

