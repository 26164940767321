import { ACTION_GET_COMMENT, ACTION_GET_COMMENT_SUCCESS, ACTION_GET_COMMENT_ERROR, ACTION_UPDATE_COMMENT_SUCCESS } from '../actions'

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const getAllCommentsReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_GET_COMMENT:
            return { ...state, isLoading: true, erro_msg: undefined }

        case ACTION_GET_COMMENT_SUCCESS:
            return { ...state, isLoading: false, data:  action.payload.data, erro_msg: undefined }

        case ACTION_UPDATE_COMMENT_SUCCESS:
            
            let nextState = {...state}
            nextState.data = [...nextState.data,  action.payload.data]
            return nextState
        case ACTION_GET_COMMENT_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }
            
        default:
            return state;
    }
}