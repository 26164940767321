import {ACTION_CREATE_NEW_THREAD, ACTION_CREATE_NEW_THREAD_SUCCESS, ACTION_CREATE_NEW_THREAD_ERROR, ACTION_GET_THREAD_DETAIL, ACTION_GET_THREAD_DETAIL_SUCCESS} from '../actions'

const initState = {
    erro_msg: undefined,
    data: undefined,
    isLoading: false
}

export const createNewThreadReducer = (state = initState, action = {}) => {
    switch (action.type) {
        case ACTION_CREATE_NEW_THREAD:
            return { ...state, isLoading: true, erro_msg: undefined, data: undefined }

        case ACTION_CREATE_NEW_THREAD_SUCCESS:
            return { ...state, isLoading: false, data:  action.payload.data, erro_msg: undefined }
        case  ACTION_GET_THREAD_DETAIL_SUCCESS:
            return { ...state, data: undefined}

        case ACTION_CREATE_NEW_THREAD_ERROR:
            return { ...state, isLoading: false, erro_msg: action.payload.msg }
            
        default:
            return state;
    }
}
