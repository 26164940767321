import { ACTION_CLOSE_THREAD, ACTION_CLOSE_THREAD_ERROR, ACTION_CLOSE_THREAD_SUCCESS } from '.'
import axios from 'axios'
import * as api from '../../../api'
import { logout } from './logout'

export const closeThreadRequest = () => ({
    type: ACTION_CLOSE_THREAD,
    payload: {
    }
})

export const closeThreadSuccess = data => ({
    type: ACTION_CLOSE_THREAD_SUCCESS,
    payload: {
        data
    }
})


export const closeThreadError = msg => ({
    type: ACTION_CLOSE_THREAD_ERROR,
    payload: {
        msg
    }
})


export const closeThread = (confirm, auth) => {
    return dispatch => {
        dispatch(closeThreadRequest())

        api.closeThread(confirm, auth)
            .then(({ data }) => {
                if (data.result.code !== 0) {
                    dispatch(closeThreadError("error"));
                    return
                }
                dispatch(closeThreadSuccess(data))
            }).catch((err) => {
                console.log(err)
                if (err.response) {
                    if (err.response.status === 401) {
                        dispatch(logout())
                        return
                    }
                }
                dispatch(closeThreadError("system error"))
            });
    }
}
