import './App.css';
import Home from './routes/home';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import CreateNewThread from './routes/createNewThread';
import Login from './routes/login';
import React , {useEffect, useSelector} from 'react';
import { connect } from 'react';
import axios from 'axios';
import NotFound from './routes/notfound';
import UnAuthen from './routes/unAuthen';
import { SecureScreen } from './routes/secure';
import { ThreadDetails } from './routes/threadDetails';


export default function App() { 

  const fetchCsrf = () => {
		const instance = axios.create({
			timeout: 1000
		})
	}

	useEffect(() => {
		fetchCsrf()
	}, [])

  return (
      <Switch>
        <Route path={"/login/:token"} component={Login} />
        <Route exact path={"/"} render={(props) => (<SecureScreen component={<Home {...props}  />} />)} />
        <Route exact path={"/thread_detail/:tid"} render={(props) => (<SecureScreen component={<ThreadDetails {...props}  />} />)}/>
        <Route exact path={"/thread_create"} render={(props) => (<SecureScreen component={<CreateNewThread {...props}  />} />)} />
        <Route path="*" component={NotFound} />
      </Switch>

  );
}

// class App extends React.Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//     }
//   }

//   componentDidMount() {
//     this.props.GetUsers();
//   }

//   render() {
//     console.log("this.props.tasksss ", this.props.Loading);
//     return (
//       <Switch>
//         <Route exact path={"/login/:token"} component={Login} />
//         <Route exact path={"/"} component={Home} />
//         <Route exact path={"/add"} component={AddComment} />
//         <Route exact path={"/create_new_thread"} component={CreateNewThread} />
//         <Route exact path={"/confirm_end"} component={ConfirmEnd} />
//         <Route exact path={"/rate"} component={Rate} />

//       </Switch>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   Loading: state.task.loading
// });

// const mapDispacthToProps = dispatch => {
//   return {
//     GetUsers: () => dispatch(GetUsers())
//   };

// };
// export default connect(
//   mapStateToProps,
//   mapDispacthToProps
// )(App);


